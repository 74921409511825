import Head from 'next/head';
import React, { FC } from 'react';

type MetaDataProps = {
  locale: string;
  seoTitle?: string;
  seoDescription?: string;
  seoKeywords?: string;
  seoSocialTitle?: string;
  seoSocialDescription?: string;
  seoCanonicalURL?: string;
  seoRobots?: string;
  seoStructuredData?: any;
  seoImage?: { media: { file: string } };
  hreflang: { href: string; lang: 'en-GB' | 'en-DE' | 'de-DE' | 'fr-FR' }[];
};

export const Metadata: FC<MetaDataProps> = (props) => {
  const {
    locale,
    seoTitle,
    seoDescription,
    seoKeywords,
    seoSocialTitle,
    seoSocialDescription,
    seoRobots,
    seoCanonicalURL,
    seoStructuredData,
    seoImage,
    hreflang,
  } = props;

  return (
    <Head>
      <meta name="viewport" content="width=device-width,initial-scale=1,shrink-to-fit=no" />
      <meta httpEquiv="content-type" content="text/html; charset=UTF-8"></meta>
      {seoTitle && <title>{seoTitle}</title>}
      {seoDescription && <meta name="description" content={seoDescription} />}
      {seoSocialTitle !== '' ? (
        <meta property="og:title" content={seoSocialTitle} />
      ) : (
        <meta property="og:title" content={seoTitle} />
      )}
      {seoSocialDescription !== '' ? (
        <meta property="og:description" content={seoSocialDescription} />
      ) : (
        <meta property="og:description" content={seoDescription} />
      )}
      {seoImage && <meta property="og:image" content={seoImage.media.file} />}
      {seoKeywords && (
        <meta
          name="keywords"
          content={seoKeywords
            ?.split(',')
            .map((keyword) => keyword.trim())
            .join(', ')}
        />
      )}
      {seoRobots && (
        <meta
          name="robots"
          content={seoRobots
            ?.split(',')
            .map((metaRobot) => metaRobot.trim())
            .join(', ')}
        />
      )}
      {seoCanonicalURL && <link rel="canonical" href={seoCanonicalURL} />}
      {seoStructuredData && (
        <script type="application/ld+json" dangerouslySetInnerHTML={{ __html: seoStructuredData }} />
      )}
      {hreflang.map((item) => (
        <link key={item.href} rel="alternate" href={item.href} hrefLang={item.lang} />
      ))}
    </Head>
  );
};

export default Metadata;
